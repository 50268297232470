import {compose, withProps, withState} from 'recompose';
import { withStyles } from '@material-ui/core/styles';
import styles from './CoreLayout.styles';
import { UserIsAuthenticated } from 'utils/router';
import { withRouter } from 'react-router-dom';
import { spinnerWhileLoading } from 'utils/components';
import { connect } from 'react-redux';

export default compose(
  withRouter,

  UserIsAuthenticated,

  connect(({ firebase: { profile, auth } }) => ({
    profile,
    auth
  })),
  spinnerWhileLoading(['profile', 'auth']),
  withState('open', 'setOpen', true),
  // Add styles as classes prop
  withStyles(styles)
);
