import { PROJECT_ID, PROJECT_INIT, PROJECT_URL } from './actionTypes';

export function loadProjectId(projectId) {
  return dispatch => {
    dispatch({ type: PROJECT_ID, payload: projectId });
  };
}
export function loadProjectUrl(url) {
  return dispatch => {
    dispatch({ type: PROJECT_URL, payload: url });
  };
}
export function loadProjectSuccess(payload) {
  return dispatch => {
    dispatch({ type: PROJECT_INIT, payload: payload });
  };
}
