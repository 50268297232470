import { compose, withHandlers } from 'recompose';
import { connect } from 'react-redux';
import * as actions from '../actions';

const mapDispatchToProps = dispatch => ({
  showError: err => {
    dispatch(actions.showError(err));
  },
  showSuccess: message => {
    dispatch(actions.showSuccess(message));
  },
  dismissNotification: id => {
    dispatch(actions.dismissNotification(id));
  }
});
const withNotifications = compose(
  connect(
    null,
    mapDispatchToProps
  ),
  withHandlers({
    showError: props => err => props.showError(err),
    showSuccess: props => message => props.showSuccess(message),
    dismissNotification: props => id => props.dismissNotification(id)
  })
);

export default withNotifications;
