import React from 'react';
import PropTypes from 'prop-types';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import IconButton from '@material-ui/core/IconButton';
import AccountCircle from '@material-ui/icons/AccountCircle';
import { withStyles } from '@material-ui/core/styles';

const styles = {
  buttonRoot: {
    color: 'white'
  }
};

const AccountMenu = ({
  onLogoutClick
}) => (
    <div>
      <IconButton onClick={onLogoutClick}>Sign Out</IconButton>
    </div>
  );

AccountMenu.propTypes = {
  onLogoutClick: PropTypes.func.isRequired,
};

export default withStyles(styles)(AccountMenu);
