import React from 'react';
import PropTypes from 'prop-types';
import CircularProgress from '@material-ui/core/CircularProgress';
import { Spinner } from 'reactstrap';


export const LoadingSpinner = ({ size, classes }) => (
  <div className={classes.root}>
    <div className={classes.progress}>
      <Spinner size="sm" type="grow" color="secondary" />
        <Spinner size="sm" type="grow" color="secondary" />
          <Spinner size="sm" type="grow" color="secondary" />
    </div>
  </div>
);

LoadingSpinner.propTypes = {
  classes: PropTypes.object.isRequired,
  size: PropTypes.number
};

export default LoadingSpinner;
