import { connectedRouterRedirect } from 'redux-auth-wrapper/history4/redirect';
import locationHelperBuilder from 'redux-auth-wrapper/history4/locationHelper';
import LoadingSpinner from 'components/LoadingSpinner';
import { DASHBOARD_PATH, EMAIL_VERIFICATION, SUBSCRIPTION_PATH, LOGIN_PATH } from 'constants/paths';
import * as qs from "query-string";
import rules from "../rbac-rules";
const locationHelper = locationHelperBuilder({});
const history = require('history').createBrowserHistory;

const AUTHED_REDIRECT = 'AUTHED_REDIRECT';
const UNAUTHED_REDIRECT = 'UNAUTHED_REDIRECT';
const VERIFIED_REDIRECT = 'VERIFIED_REDIRECT';
const UNVERIFIED_REDIRECT = 'UNVERIFIED_REDIRECT';
const NO_SUBSCRIPTION_REDIRECT = 'NO_SUBSCRIPTION_REDIRECT';
const HAS_SUBSCRIPTION_REDIRECT = 'HAS_SUBSCRIPTION_REDIRECT';
const NO_PERMISSION_REDIRECT = 'NO_PERMISSION_REDIRECT';

/**
 * Higher Order Component that redirects to `/login` instead
 * rendering if user is not authenticated (default of redux-auth-wrapper).
 * @param {Component} componentToWrap - Component to wrap
 * @return {Component} wrappedComponent
 */
export const UserIsAuthenticated = connectedRouterRedirect({
  redirectPath: '/login',
  AuthenticatingComponent: LoadingSpinner,
  wrapperDisplayName: 'UserIsAuthenticated',
  // Want to redirect the user when they are done loading and authenticated
  authenticatedSelector: ({ firebase: { auth } }) => !auth.isEmpty && !!auth.uid,
  authenticatingSelector: ({ firebase: { auth, isInitializing } }) => !auth.isLoaded || isInitializing,
  redirectAction: newLoc => dispatch => {
    // Use push, replace, and go to navigate around.
    history.push(newLoc);
    dispatch({
      type: UNAUTHED_REDIRECT,
      payload: { message: 'User is not authenticated.' }
    });
  }
});
export const UrlHasOrgId = connectedRouterRedirect({
  redirectPath: '/account',
  AuthenticatingComponent: LoadingSpinner,
  wrapperDisplayName: 'UserHasOrgId',
  // Want to redirect the user when they are done loading and authenticated
  authenticatedSelector: () => !!qs.parse(window.location.search, { ignoreQueryPrefix: true }).o,
  authenticatingSelector: () => false,
  redirectAction: newLoc => dispatch => {
    // Use push, replace, and go to navigate around.
    history.push(newLoc);
    dispatch({
      type: UNAUTHED_REDIRECT,
      payload: { message: 'Need Org' }
    });
  }
});

/**
 * Higher Order Component that redirects to listings page or most
 * recent route instead rendering if user is not authenticated. This is useful
 * routes that should not be displayed if a user is logged in, such as the
 * login route.
 * @param {Component} componentToWrap - Component to wrap
 * @return {Component} wrappedComponent
 */
export const UserIsNotAuthenticated = connectedRouterRedirect({
  AuthenticatingComponent: LoadingSpinner,
  wrapperDisplayName: 'UserIsNotAuthenticated',
  allowRedirectBack: false,
  // Want to redirect the user when they are done loading and authenticated
  authenticatedSelector: ({ firebase: { auth } }) => auth.isEmpty,
  authenticatingSelector: ({ firebase: { auth, isInitializing } }) => !auth.isLoaded || isInitializing,
  redirectPath: (state, ownProps) => locationHelper.getRedirectQueryParam(ownProps) || EMAIL_VERIFICATION,
  redirectAction: newLoc => dispatch => {
    // Use push, replace, and go to navigate around.
    history.push(newLoc);
    dispatch({
      type: AUTHED_REDIRECT,
      payload: { message: 'User is not authenticated.' }
    });
  }
});

export const UserHasToken = connectedRouterRedirect({
  AuthenticatingComponent: LoadingSpinner,
  wrapperDisplayName: 'UserIsNotAuthenticated',
  allowRedirectBack: false,
  // Want to redirect the user when they are done loading and authenticated
  authenticatedSelector: ({ firebase: { auth } }) => false,
  authenticatingSelector: ({ firebase: { auth, isInitializing } }) => !auth.isLoaded || isInitializing,
  redirectPath: (state, ownProps) => locationHelper.getRedirectQueryParam(ownProps) || EMAIL_VERIFICATION,
  redirectAction: newLoc => dispatch => {
    // Use push, replace, and go to navigate around.
    history.push(newLoc);
    dispatch({
      type: AUTHED_REDIRECT,
      payload: { message: 'User is not authenticated.' }
    });
  }
});

/**
 * Higher Order Component that redirects to verification page or most
 * recent route instead rendering if user is not verified. This is useful
 * routes that should not be displayed if a user is not verified.
 * @param {Component} componentToWrap - Component to wrap
 * @return {Component} wrappedComponent
 */
export const UserIsVerified = connectedRouterRedirect({
  redirectPath: '/email-verification',
  AuthenticatingComponent: LoadingSpinner,
  wrapperDisplayName: 'UserIsVerified',
  // Want to redirect the user when they are done loading and authenticated
  authenticatedSelector: ({ firebase: { auth, profile } }) =>
    (!auth.isEmpty && profile.role === 'admin' && auth.emailVerified) || !profile.role || profile.role !== 'admin',
  authenticatingSelector: ({ firebase: { auth, isInitializing, profile } }) =>
    !profile.isLoaded || !auth.isLoaded || isInitializing,
  redirectAction: newLoc => dispatch => {
    // Use push, replace, and go to navigate around.
    history.push(newLoc);
    dispatch({
      type: UNVERIFIED_REDIRECT,
      payload: { message: 'User is not verified.' }
    });
  }
});

/**
 * Higher Order Component that redirects away from verification screen
 * This is useful for routes that should not be displayed if a user is
 * logged in, such as the login route.
 * @param {Component} componentToWrap - Component to wrap
 * @return {Component} wrappedComponent
 */
export const UserIsNotVerified = connectedRouterRedirect({
  AuthenticatingComponent: LoadingSpinner,
  wrapperDisplayName: 'UserIsNotVerified',
  allowRedirectBack: false,
  // Want to redirect the user when they are done loading and verified
  authenticatedSelector: ({ firebase: { auth } }) => auth.isEmpty || !auth.emailVerified,
  authenticatingSelector: ({ firebase: { auth, isInitializing } }) => !auth.isLoaded || isInitializing,
  redirectPath: (state, ownProps) => locationHelper.getRedirectQueryParam(ownProps) || '/admin'+DASHBOARD_PATH,
  redirectAction: newLoc => dispatch => {
    // Use push, replace, and go to navigate around.
    history.push(newLoc);
    dispatch({
      type: VERIFIED_REDIRECT,
      payload: { message: 'User is not verified.' }
    });
  }
});

/**
 * Higher Order Component that redirects to `/subscription`
 * if they don't have one
 * @param {Component} componentToWrap - Component to wrap
 * @return {Component} wrappedComponent SUBSCRIPTION_PATH
 */
export const UserHasSubscription = connectedRouterRedirect({
  redirectPath: '/admin/subscription',
  AuthenticatingComponent: LoadingSpinner,
  wrapperDisplayName: 'UserHasSubscription',
  // Want to redirect the user when they are done loading and subscribed
  authenticatedSelector: ({ firebase: { profile } }) => !!(profile.role !== 'admin' || profile.subscription),
  authenticatingSelector: ({ firebase: { profile, isInitializing } }) => !!(!profile.isLoaded || isInitializing),
  // This prevents us from adding the query parameter when we send the user away from the login page
  allowRedirectBack: false,
  redirectAction: newLoc => dispatch => {
    // Use push, replace, and go to navigate around.
    history.push(newLoc);
    dispatch({
      type: NO_SUBSCRIPTION_REDIRECT,
      payload: { message: 'User has subscription.' }
    });
  }
});

/**
 * Higher Order Component that redirects away from subscription screen
 * This is useful for routes that should not be displayed if a user has
 * subscription already
 * @param {Component} componentToWrap - Component to wrap
 * @return {Component} wrappedComponent
 */
export const UserHasNoSubscription = connectedRouterRedirect({
  AuthenticatingComponent: LoadingSpinner,
  wrapperDisplayName: 'UserHasNoSubscription',
  allowRedirectBack: false,
  // Want to redirect the user when they are done loading and subscribed
  authenticatedSelector: ({ firebase: { profile } }) => !!(profile.role === 'admin' && !profile.subscription),
  authenticatingSelector: ({ firebase: { profile, isInitializing } }) => !profile.isLoaded || isInitializing,
  redirectPath: (state, ownProps) => locationHelper.getRedirectQueryParam(ownProps) || DASHBOARD_PATH,
  redirectAction: newLoc => dispatch => {
    // Use push, replace, and go to navigate around.
    history.push(newLoc);
    dispatch({
      type: HAS_SUBSCRIPTION_REDIRECT,
      payload: { message: 'User is not verified.' }
    });
  }
});

const checkRoleHasPermission = (permissions, role) => {
  var can = true;
  permissions.forEach(e => {
    if (!role[e]) can = false;
  });
  return can;
};

const checkToken = () => {
  var can = false;
  return can;
};

const check = (action,role, rules) => {
  const permissions = rules[role];
  let can = true;
  if (!permissions) {
    can = false;
  }
  const staticPermissions = permissions.static;
  if (staticPermissions && staticPermissions.includes(action)) {
    // static rule not provided for action
    return can = true;
  }
  else{
    can = false;
  }

  return can;

};


export const UserHasPermission = permissions =>
  connectedRouterRedirect({
    wrapperDisplayName: 'UserHasPermission',
    allowRedirectBack: false,
    authenticatedSelector: ({
      firebase: { auth, profile }
    }) => !auth.isEmpty && !!auth.uid && !profile.isEmpty && profile.role && check(permissions, profile.role, rules),
    authenticatingSelector: ({
      firebase: { auth, isInitializing, profile }
    }) => !profile.role || !auth.isLoaded || isInitializing || !profile.isLoaded,
    redirectPath: '/admin/screens',
    redirectAction: newLoc => dispatch => {
      // Use push, replace, and go to navigate around.
      history.replace(newLoc);
      dispatch({
        type: NO_PERMISSION_REDIRECT,
        payload: { message: `User has no ${permissions} permission.` }
      });
    }
  });

export const UserIsAdmin = connectedRouterRedirect({
  wrapperDisplayName: 'UserIsAdmin',
  allowRedirectBack: false,
  authenticatedSelector: ({ firebase: { profile } }) => !profile.isEmpty && profile.role === 'admin',
  authenticatingSelector: ({ firebase: { isInitializing, profile } }) => isInitializing || !profile.isLoaded,
  redirectPath: '/admin/playlist',
  redirectAction: newLoc => dispatch => {
    // Use push, replace, and go to navigate around.
    history.replace(newLoc);
    dispatch({
      type: NO_PERMISSION_REDIRECT,
      payload: { message: `User is not an admin.` }
    });
  }
});
