import { PROJECT_ID, PROJECT_INIT, PROJECT_URL } from './actionTypes';

const initialState = {
  projectId: '',
  uid: '',
  url: ''
};
const project = (state = initialState, action) => {
  switch (action.type) {
    case PROJECT_ID:
      return { ...state, projectId: action.payload };
    case PROJECT_URL:
      return { ...state, url: action.payload };
    case PROJECT_INIT:
      return { ...state, projectId: action.payload.projectId, url: action.payload.url, uid: action.payload.uid };
    default:
      return state;
  }
};
export default project;
