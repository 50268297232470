import { darken } from "@material-ui/core/styles";

export default {
  palette: {
    primary: {
      main: '#ff7900',
      dark: '#d67015'
    },
    secondary: {
      main: '#f3ac93'
    },
    blueButton: {
      main: '#2228B3'
    },
    disabled: {
      main: '#6a5c53'
    }
  },
  typography: {
    useNextVariants: true,
    fontFamily: [
      'SofiaPro-Regular',
      '-apple-system',
      'BlinkMacSystemFont',
      '"Segoe UI"',
      'Roboto',
      '"Helvetica Neue"',
      'Arial',
      'sans-serif',
      '"Apple Color Emoji"',
      '"Segoe UI Emoji"',
      '"Segoe UI Symbol"'
    ].join(',')
  },
  flexColumnCenter: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center'
  },
  flexRowCenter: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'center'
  },
  center: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'center'
  }
};
