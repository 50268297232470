import React, { Component, useContext, useState } from "react";
import Switch from "react-bootstrap-switch";
import { Button } from "reactstrap";
import { LayoutContext } from '../../layouts/CoreLayout/components/LayoutContext';
import PropTypes from 'prop-types';

const FixedPlugin = ({
    activeColor,
    sidebarMini,
    profile,
    handleActiveClick,
    handleMiniClick}) => {

    const { state } = useContext(LayoutContext);
    const [classes, setClasses] = useState("dropdown");
    const [darkMode, setDarkMode] = useState(true);
    const handleClick = () => {
        if (classes === "dropdown") {
          setClasses("dropdown show" );
        } else {
          setClasses("dropdown");
        }
      };

    const handleActiveMode = () => {
      setDarkMode(!darkMode);
      document.body.classList.toggle("white-content");
    };

    return (
      <div className="fixed-plugin">
        <div className={classes}>
          <a
            href="#pablo"
            onClick={e => {
              e.preventDefault();
              handleClick();
            }}
          >
          <i style={{fontSize:'16px'}} className="tim-icons icon-align-center icon-settings-gear-63 text-white"  />
          </a>
          <ul className="dropdown-menu show">
            <li className="adjustments-line">
              <div className="badge-colors text-center">
                <span
                  className={
                    activeColor === "primary"
                      ? "badge filter badge-primary active"
                      : "badge filter badge-primary"
                  }
                  data-color="primary"
                  onClick={() => {
                    handleActiveClick("primary");
                  }}
                />
                <span
                  className={
                    activeColor === "blue"
                      ? "badge filter badge-info active"
                      : "badge filter badge-info"
                  }
                  data-color="info"
                  onClick={() => {
                    handleActiveClick("blue");
                  }}
                />
                <span
                  className={
                    activeColor === "green"
                      ? "badge filter badge-success active"
                      : "badge filter badge-success"
                  }
                  data-color="success"
                  onClick={() => {
                    handleActiveClick("green");
                  }}
                />
                <span
                  className={
                    activeColor === "orange"
                      ? "badge filter badge-warning active"
                      : "badge filter badge-warning"
                  }
                  data-color="warning"
                  onClick={() => {
                    handleActiveClick("orange");
                  }}
                />
                <span
                  className={
                    activeColor === "red"
                      ? "badge filter badge-danger active"
                      : "badge filter badge-danger"
                  }
                  data-color="danger"
                  onClick={() => {
                    handleActiveClick("red");
                  }}
                />
              </div>
            </li>
            <li className="adjustments-line">
              <div className="togglebutton switch-sidebar-mini">
                <span className="label-switch">Sidebar</span>
                <Switch
                  onChange={handleMiniClick}
                  value={sidebarMini}
                  onText=""
                  offText=""
                />
              <span className="label-switch">Mini</span>
              </div>
            </li>
            <li className="adjustments-line">
              <div className="togglebutton switch-change-color mt-3">
                <span className="label-switch">Light</span>
                <Switch
                  onChange={handleActiveMode}
                  value={darkMode}
                  onText=""
                  offText=""
                />
              <span className="label-switch">Dark</span>
              </div>
            </li>
          </ul>
        </div>
      </div>
    );
    }

  FixedPlugin.propTypes = {
    profile: PropTypes.object // from enhancer (withStateHandlers - handleMenu)
  };

export default FixedPlugin;
