export const LIST_PATH = '/projects';
export const HOME_PATH = '/';
export const EMAIL_VERIFICATION = '/email-verification';
export const LOGIN_PATH = '/login';
export const REGISTER_PATH = '/register';
export const REGISTER_PATH_AS = '/registration';
export const CHARTS_PATH = '/charts';
export const LANDING_PATH = '/landing';
export const SIGNUP_PATH = '/signup';
export const SUBSCRIPTION_PATH = '/subscription';
export const TABLE_PATH = '/table';
export const EMPLOYEES_PATH = '/employees';
export const INVITATION_PATH = '/invitation';
export const DASHBOARD_PATH = '/dashboard';
export const SCREEN_DASHBOARD_PATH = '/screendashboard';
export const MATERIAL_MASTER_PATH = '/material-master';
export const STAFF_MASTER_PATH = '/staff-master';
export const SETTINGS_PATH = '/settings'
export const PRICING_PATH = '/pricing';;
export const TIMESHEETS_PATH = '/timesheets';
export const TERMS_PATH = '/terms';
export const PIXELBAY_PATH = '/pixelbay'
export const ACCOUNT_PATH = '/account';
export const BOARD_PATH = '/board';
export const PLAYLIST_PATH = '/playlist';
export const HELP_PATH = '/help';
export const SUPPORT_ADMIN_PATH = '/supportadmin';
export const PUBLIC_DROPZONE_PATH = '/dropzonepublic';
export const UPLOAD_PATH = '/upload';
export const PUBLIC_PLAYLIST_PATH = '/publicplaylist/:playlistId?';
export const PUBLIC_PLAYLIST_PATH_ROOT = '/publicplaylist';
export const UPLOAD_PLAYLIST_PATH = '/upload/:playlistId?';
export const SCREEN_DETAIL_PATH = '/screen/:screenId?';
export const SCREEN_DETAIL_PATH_ROOT = '/screen';
export const EDITOR_PATH = '/editor';
export const METRICS_PATH = '/metrics';
export const SCREENS_PATH = '/screens';
export const MAP_PATH = '/map';
export const PROFILE_PATH = '/profile';
export const MAPPING_PATH = '/mapping';
export const LISTS_PATH = '/lists';
export const CACHES_PATH = '/caches';
export const SUPPLIERS_PATH = '/suppliers';
export const PRODUCTS_PATH = '/products';
export const BUILDER_PATH = '/builder';
export const ASSETS_PATH = '/assets';
export const PUBLIC_ASSETS_PATH = '/view-assets';
