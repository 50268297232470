import React from 'react';
import MaterialIcon from 'mdi-react/ClipboardCheckOutlineIcon';
import LineItemIcon from 'mdi-react/FormatListNumberedIcon';
import AccountIcon from 'mdi-react/AccountBoxIcon';
import ProjectsIcon from 'mdi-react/ViewListIcon';
import Employeesicon from 'mdi-react/AccountGroupIcon';
import DashboardIcon from '@material-ui/icons/Dashboard';
import ReceiptIcon from '@material-ui/icons/Receipt';
import TocIcon from '@material-ui/icons/Toc';
import { STAFF_MASTER_PATH } from 'constants/paths';
import TableChartIcon from '@material-ui/icons/TableChart';
import SettingsIcon from '@material-ui/icons/Settings';

export const mapping = {
  default: [
    { name: 'Dashboard', path: '/dashboard', icon: <DashboardIcon /> },
    { name: 'Account', path: '/account', icon: <AccountIcon /> },
    { name: 'Employee Setup', path: '/employees', icon: <Employeesicon /> },
    { name: 'Material Master', path: '/material-master', icon: <ReceiptIcon /> },
    { name: 'Timesheet Master', path: STAFF_MASTER_PATH, icon: <TocIcon /> }
  ],
  employee: [
    { name: 'Dashboard', path: '/', icon: <DashboardIcon /> },
    { name: 'Account', path: '/account', icon: <AccountIcon /> }
  ],
  home: [
    { name: 'Home', path: '/' },
    { name: 'Account', path: '/account' },
    { name: 'Projects', path: '/projects' },
    { name: 'Table', path: '/table' },
    { name: 'Employees', path: '/employees' },
    { name: 'Timesheets', path: '/timesheets' }
  ],
  account: [
    { name: 'Home', path: '/' },
    { name: 'Account', path: '/account' },
    { name: 'Projects', path: '/projects' },
    { name: 'Table', path: '/table' }
  ],
  projects: [
    { name: 'Home', path: '/' },
    { name: 'Account', path: '/account' },
    { name: 'Projects', path: '/projects' },
    { name: 'Table', path: '/table' }
  ],
  table: [
    { name: 'Home', path: '/' },
    { name: 'Account', path: '/account' },
    { name: 'Projects', path: '/projects' },
    { name: 'Table', path: '/table' }
  ],
  dashboard: [],
  project: url => [
    {
      name: 'Materials',
      path: url + '/materials',
      icon: <MaterialIcon />
    },
    {
      name: 'Timesheets',
      path: url + '/timesheets',
      icon: <TableChartIcon />
    },
    // {
    //   name: 'Line Items',
    //   path: url + '/add-line-items',
    //   icon: <LineItemIcon />
    // }
  ]
};
export default mapping;
