import React from 'react';
import { Router, Route, Switch, Redirect } from "react-router-dom";
import CoreLayout from '../layouts/CoreLayout/components';
import LoginRoute from './Login';
import RegisterRoute from './Register';
import RegistrationRoute from './RegisterAS';
import EmailVerificationRoute from './EmailVerification';
import NotFoundRoute from './NotFound';
import InvitationRoute from './Invitation';
import PricingRoute from './Pricing';
import Terms from './Terms';
import PixelBay from './PixelBay';
import ReactGA from 'react-ga';

import * as firebase from 'firebase';
import AdminLayout from "layouts/Admin/Template/Admin";
import { useSelector } from 'react-redux';
import { isLoaded, isEmpty } from 'react-redux-firebase';
import { useState, useEffect } from "react";
import WatchLayout from "layouts/Watch/Watch.js";

import { createBrowserHistory } from "history";
import NotificationAlert from "react-notification-alert";

import {ErrorBoundary} from 'react-error-boundary'

// reactstrap components
import {
  UncontrolledAlert,
  Alert,
  Button,
  Card,
  CardHeader,
  CardBody,
  CardTitle,
  Modal,
  ModalBody,
  Row,
  Col
} from "reactstrap";


export const AuthContext = React.createContext();
const getUserFromToken = token => {
  if (token) {
    try {
      return JSON.parse(atob(token.split('.')[1]));
    } catch (error) {
      // ignore
    }
  }

  return null;
};

/*const firebaseToken = async setFirebaseToken = (token) => {
  const response = await fetch('http://localhost:3001/firebase', {
    headers: {
      'Authorization': `Bearer ${auth0Client.getIdToken()}`,
    },
  }

  const data = await response.json();
  await firebaseClient.setToken(data.firebaseToken);
  await firebaseClient.updateProfile(auth0Client.getProfile());
  activateChat();
}*/

function AuthIsLoaded({ children }) {
  const auth = useSelector(state => state.firebase.auth);
  const urlcheck = window.location.href;

  if ((urlcheck.indexOf('shopfront=') > 1)){

    firebase.auth().onAuthStateChanged(function (user) {
        if (user) {
            console.log('User is logged in');
            return children;
        } else {
            const url = window.location.href;
            if (true) {  // The minted firebase token from Auth function
              fetch('https://mc-api-alpha-xjuagtwlxq-uc.a.run.app/v1.0/sso')
                  .then(response => response.json())
                  .then(
                    data =>
                    firebase.auth().signInWithCustomToken(data.token)
                    .then((user) => {
                        console.log(user);
                        return children;
                    })
                    .catch(function (error) {
                        // Handle Errors here.
                        var errorCode = error.code;
                        var errorMessage = error.message;
                        console.error(errorCode + ' ' + errorMessage);
                        return children;
                    })
                  )
            } else { // User not authenticated and no custom token present. Redirect to Azure AD for authentication token
                let redirectUrl = "https://us-central1-mediumcast-hub-alpha.cloudfunctions.net/sso" //TODO: Redirect to the URL of your auth function
                if (window.location.port === "5000") { // TODO what to do here Adjust the requested redirectUri for local development
                    redirectUrl = redirectUrl + "&redirect_uri=http%3A%2F%2Flocalhost%3A5000%2Fsso";
                    //redirectUrl = "http://localhost:5000";
                }
                window.location.href = redirectUrl;
                return children;
            }
        }
    });

  }

  return children;
}

function ErrorFallback({error, componentStack, resetErrorBoundary }) {
 return (
   <div>

     <div className="content">

       <Row>
         <Col md="12">

               <UncontrolledAlert
                 className="alert-with-icon"
                 color="primary"
                 fade={false}
               >
                 <span
                   data-notify="icon"
                   className="tim-icons icon-bell-55"
                 />
                 <span data-notify="message">
                   Something went wrong {error.toString().substr(1,200)}
                   <details style={{ whiteSpace: 'pre-wrap' }}>
                    {error && error.toString() && componentStack && componentStack.toString()}
                    <br />
                    {componentStack}
                    </details>     </span>
               </UncontrolledAlert>

         </Col>
       </Row>
     </div>
   </div>
       )
}

function PrivateRoute({ children, ...rest }) {
  const auth = useSelector(state => state.firebase.auth)
  return (
    <Route
      {...rest}
      render={({ location }) =>
        isLoaded(auth) && !isEmpty(auth) ? (
          children
        ) : (
          <Redirect
            to={{
              pathname: "/login",
              state: { from: location }
            }}
          />
        )
      }
    />
  );
}

export default function createRoutes(store) {
  ReactGA.pageview(window.location.pathname + window.location.search);
  const loggedIn = true;

  const hist = createBrowserHistory();

  return (
    <Router history={hist}>
    <Switch>
      <Route exact path="/">
        {loggedIn ? <Redirect to="/admin/dashboard" /> : <LoginRoute />}
      </Route>
      <Route path="/invitations/:id" render={props => <InvitationRoute.component {...props} />} />
      {[
        RegisterRoute,
        RegistrationRoute,
        LoginRoute,
        PricingRoute,
        EmailVerificationRoute,
        Terms,
        PixelBay,
        PricingRoute,
        /* Add More Routes Here */
      ].map((settings, index) => (
        <Route key={`Route-${index}`} {...settings} />
      ))}
      <AuthIsLoaded>
        <CoreLayout>
          <ErrorBoundary
            FallbackComponent={ErrorFallback}
            onReset={() => {
              // reset the state of your app so the error doesn't happen again
            }}
          >
          <Switch>
            <Route path="/admin" render={props => <AdminLayout {...props}  />} />
            <Route path="/watch" render={props => <WatchLayout {...props}  />} />
            <Route component={NotFoundRoute.component} />
          </Switch>
          </ErrorBoundary>
        </CoreLayout>
      </AuthIsLoaded>
   </Switch>
 </Router>
  );
}
