import React, { useReducer } from 'react';
import { mapping } from 'utils/sidebarMapping';

const initialState = {
  staticDrawers: mapping.default,
  employeeDrawers: mapping.employee,
  drawers: [],
  crumbs: ['home', 'projects'],
  materialMaster: [],
  lineItems: [],
  staffMaster: [],
  projects: []
};

const reducer = (state, action) => {
  switch (action.type) {
    case 'drawers':
      return { ...state, drawers: action.payload };
    case 'staticDrawers':
      return { ...state, staticDrawers: action.payload };
    case 'crumbs':
      return { ...state, crumbs: action.payload };
    case 'materialMaster':
      return { ...state, materialMaster: action.payload };
    case 'lineItems':
      return { ...state, lineItems: action.payload };
    case 'staffMaster':
      return { ...state, staffMaster: action.payload };
    case 'projects':
      return { ...state, projects: action.payload };
    default:
      throw new Error();
  }
};

const LayoutContext = React.createContext(initialState);

const LayoutProvider = ({ children, profile }) => {
  const [state, dispatch] = useReducer(reducer, initialState);
  return <LayoutContext.Provider profile={profile} value={{ state, dispatch }}>{children}</LayoutContext.Provider>;
};

export { LayoutContext, LayoutProvider };
