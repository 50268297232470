export const env = 'local';
export const brand = 'mediumcast'
export const brandtitle = 'Mediumcast'
export const brandas = 'atlasshorts'
export const brandastitle = 'Atlasshorts'

export const firebaseConfig = {
  apiKey: process.env.REACT_APP_FIREBASE_API_KEY,
  authDomain: process.env.REACT_APP_FIREBASE_AUTH_DOMAIN,
  databaseURL: process.env.REACT_APP_FIREBASE_DATABASE_URL,
  projectId: process.env.REACT_APP_FIREBASE_PROJECT_ID,
  storageBucket: process.env.REACT_APP_FIREBASE_STORAGE_BUCKET,
  messagingSenderId: process.env.REACT_APP_FIREBASE_MESSAGING_SENDER_ID ,
  appId: process.env.REACT_APP_FIREBASE_APP_ID,
  measurementId: process.env.REACT_APP_FIREBASE_MEASUREMENT_ID
};

export const canvaConfig = {
  apiKey: process.env.REACT_APP_CANVA_API_KEY
}

export const stripeConfig = {
  planPrice: process.env.REACT_APP_STRIPE_PLAN_PRICE,
  publishableKey: process.env.REACT_APP_STRIPE_PUBLISHABLE_KEY,
  subscriptionEndpoint: process.env.REACT_APP_STRIPE_SUBSCRIPTION_ENDPOINT,
  mySubscriptionEndpoint: process.env.REACT_APP_STRIPE_MY_SUBSCRIPTION_ENDPOINT,
  deleteSubscriptionEndpoint: process.env.REACT_APP_STRIPE_DELETE_SUBSCRIPTION_ENDPOINT,
  callbackEndpoint: (process.env.REACT_APP_HOST === 'localhost')?
  (`${process.env.REACT_APP_HOST_PROTOCOL}${process.env.REACT_APP_HOST}:${process.env.REACT_APP_PORT}`):
  (`${process.env.REACT_APP_HOST_PROTOCOL}${process.env.REACT_APP_HOST}`)
};

export const cloud_function_endpoints = {
  uploadCsvEndpoint: process.env.REACT_APP_CLOUD_FUNCTION_HTTP_UPLOAD_CSV,
  cfroot: `https://us-central1-${process.env.REACT_APP_FIREBASE_PROJECT_ID}.cloudfunctions.net`
};

export const reduxFirebase = {
  userProfile: 'users',
  enableClaims: true,
  useFirestoreForProfile: true, // Store in Firestore instead of Real Time DB
  enableLogging: false,
  profileFactory: ({user}, profileData) => ({
    ...profileData,
    email: user.email || (user.providerData&&user.providerData[0]&&user.providerData[0].email?user.providerData[0].email:''),
    role: 'admin',
    providerData: user.providerData
  })
};

export default {
  env,
  brand,
  firebaseConfig,
  reduxFirebase,
  cloud_function_endpoints,
  stripeConfig
};
