import React, { Component }  from 'react';
import Navbar from 'containers/Navbar';
import { Notifications } from 'modules/notification';
import { LayoutProvider } from './LayoutContext';
import FixedPlugin from "components/FixedPlugin/FixedPlugin.jsx";
import HomeIcon from "@material-ui/icons/Home";
import ReceiptIcon from "@material-ui/icons/Receipt";
import NotificationsIcon from "@material-ui/icons/Notifications";
import DesktopWindowsIcon from "@material-ui/icons/DesktopWindows";
import SettingsIcon from "@material-ui/icons/Settings";
import PropTypes from "prop-types";

function onClick(e, item) {
  window.alert(JSON.stringify(item, null, 2));
}


export const CoreLayout = ({ children, classes, setOpen, open }) => (

<>
    <LayoutProvider>
      {children}
    <Notifications />
    </LayoutProvider>
</>
);

CoreLayout.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default CoreLayout;
