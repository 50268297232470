import React from 'react';
import PropTypes from 'prop-types';
import { BrowserRouter as Router } from 'react-router-dom';
import { Provider } from 'react-redux';
import { ReactReduxFirebaseProvider } from 'react-redux-firebase';
import { ThemeProvider } from '@material-ui/styles';
import { createMuiTheme } from '@material-ui/core/styles';
import theme from 'theme.js';
import {firebaseConfig} from 'config';

window.host =
  !process.env.NODE_ENV || process.env.NODE_ENV === 'development'
    ? 'http://localhost:3000/'
    : `https://${firebaseConfig.projectId}.web.app/`;

const App = ({ routes, store, persistor }) => (
  <Provider store={store}>
    <ReactReduxFirebaseProvider {...store.rrfProps}>
        <Router>{routes}</Router>
    </ReactReduxFirebaseProvider>
  </Provider>
);

App.propTypes = {
  routes: PropTypes.object.isRequired,
  store: PropTypes.object.isRequired
};
App.contextTypes = { store: PropTypes.object };

export default App;
