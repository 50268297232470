import { connect } from 'react-redux';
import { withHandlers, compose, flattenProp } from 'recompose';
import { withFirebase } from 'react-redux-firebase';
import styles from './FixedPlugin.styles';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';

export default compose(
  // Map redux state to props
  connect(({ firebase: { auth, profile } }) => ({
    auth,
    profile
  })),
 // Flatten profile so that avatarUrl and displayName are props
 flattenProp('profile'),
 // Add styles as classes prop
 withStyles(styles)
);
