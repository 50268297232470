import Dashboard from "routes/Dashboard";
import Map from "routes/Map";
import Playlists from "routes/Playlists";
import Help from "routes/Help";
import SupportAdmin from "routes/SupportAdmin";
import Screens from "routes/Screens";
import Screen from "routes/ScreensDetail";
import Upload from "routes/Upload";
import Profile from "routes/Profile";
import PlaylistPublic from "routes/PlaylistPublic";
import Account from "routes/Account";

import { PLAYLIST_PATH,
  PUBLIC_PLAYLIST_PATH,
  UPLOAD_PLAYLIST_PATH,
  SCREEN_DETAIL_PATH,
  PROFILE_PATH,
  HELP_PATH,
  SUPPORT_ADMIN_PATH,
  PUBLIC_PLAYLIST_PATH_ROOT,
  UPLOAD_PATH,
  ACCOUNT_PATH
} from "constants/paths";

var routes = [
  {
    path: "/dashboard",
    name: "Dashboard",
    rtlName: "لوحة القيادة",
    icon: "tim-icons icon-chart-pie-36",
    component: Dashboard,
    layout: "/admin",
    roles: ["admin"]
  },
  {
    path: "/",
    name: false,
    component: Dashboard,
    layout: "/admin",
    roles: ["admin"]
  },
  {
    path: "/screens",
    name: "Screens",
    rtlName: "الرموز",
    icon: "tim-icons icon-tv-2",
    component: Screens,
    layout: "/admin",
    roles: ["admin"]
  },
  {
    path: PLAYLIST_PATH,
    name: "Playlists",
    rtlName: "الرموز",
    icon: "tim-icons icon-minimal-right",
    component: Playlists,
    layout: "/admin",
    roles: ["admin"]
  },
  {
    path: UPLOAD_PATH,
    name: "Create Playlist",
    rtlName: "قائمة الجدول",
    icon: "tim-icons icon-cloud-upload-94",
    component: Upload,
    layout: "/admin",
    roles: ["admin"]
  },
  {
    path: UPLOAD_PLAYLIST_PATH,
    name: false,
    component: Upload,
    layout: "/admin",
    roles: ["admin"]
  },
  {
    path: SCREEN_DETAIL_PATH,
    name: false,
    component: Screen,
    layout: "/admin",
    roles: ["admin"]
  },
  {
    path: PUBLIC_PLAYLIST_PATH_ROOT,
    name: false,
    rtlName: "الرموز",
    icon: "tim-icons icon-atom",
    component: PlaylistPublic,
    layout: "/admin",
    roles: ["admin"]
  },
  {
    path: PUBLIC_PLAYLIST_PATH,
    name: false,
    component: PlaylistPublic,
    layout: "/admin",
    roles: ["admin"]
  },
  {
    path: "/map",
    name: "Map",
    rtlName: "خرائط",
    icon: "tim-icons icon-pin",
    component: Map,
    layout: "/admin",
    roles: ["admin"]
  },
  {
    path: "/profile",
    name: "Profile",
    rtlName: "ملف تعريفي للمستخدم",
    icon: "tim-icons icon-single-02",
    component: Profile,
    layout: "/admin",
    roles: ["admin"]
  },
  {
    path: `${HELP_PATH}`,
    name: "Help",
    rtlName: "الرموز",
    icon: "tim-icons icon-support-17",
    component: Help,
    layout: "/admin",
    roles: ["admin"]
  },
  {
    path: `${SUPPORT_ADMIN_PATH}`,
    name: "Support Admin",
    rtlName: "الرموز",
    icon: "tim-icons icon-settings",
    component: SupportAdmin,
    layout: "/admin",
    roles: ["admin"]
  },
  {
    path: `${ACCOUNT_PATH}`,
    name: "Account",
    rtlName: "الرموز",
    icon: "tim-icons icon-wallet-43",
    component: Account,
    layout: "/admin",
    roles: ["admin"]
  }
];
export default routes;
