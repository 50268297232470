import { compose, withHandlers } from 'recompose';
import { connect } from 'react-redux';
import * as actions from './actions';

const mapDispatchToProps = dispatch => ({
  loadProjectId: projectId => {
    dispatch(actions.loadProjectId(projectId));
  },
  loadProjectUrl: url => {
    dispatch(actions.loadProjectUrl(url));
  },
  loadProjectSuccess: payload => {
    dispatch(actions.loadProjectSuccess(payload));
  }
});
const withProject = compose(
  connect(
    null,
    mapDispatchToProps
  ),
  withHandlers({
    loadProjectId: props => projectId => props.loadProjectId(projectId),
    loadProjectUrl: props => url => props.loadProjectUrl(url),
    loadProjectSuccess: props => payload => props.loadProjectSuccess(payload)
  })
);

export default withProject;
