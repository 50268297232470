import { connect } from 'react-redux';
import { withHandlers, compose, withProps, flattenProp, withStateHandlers, withState, setPropTypes } from 'recompose';
import { withStyles } from '@material-ui/core/styles';
import { withRouter } from 'react-router-dom';
import { withFirebase, isEmpty, isLoaded } from 'react-redux-firebase';
import { ACCOUNT_PATH, LOGIN_PATH, PROFILE_PATH } from 'constants/paths';
import styles from './Sidebar.styles';
import PropTypes from 'prop-types';

export default compose(
  // Map redux state to props
  connect(({ firebase: { auth, profile } }) => ({
    auth,
    profile
  })),
  // State handlers as props
  // Add props.router (used in handlers)
  withRouter,
  // Add props.firebase (used in handlers)
  withFirebase,
  // Handlers as props
  withHandlers({
   handleLogout: props => () => {
     props.firebase.logout();
     props.history.push(LOGIN_PATH);
   },
   goToAccount: props => () => {
     props.history.push(ACCOUNT_PATH);
   },
   goToProfile: props => () => {
     props.history.push(`/admin${PROFILE_PATH}`);
   },
   handleDrawerOpen: props => () => {
     props.setOpen(!props.open);
   }
 }),
 // Add custom props
 withProps(({ auth, profile }) => ({
   authExists: isLoaded(auth) && !isEmpty(auth),
   profile:profile
 })),
 // Flatten profile so that avatarUrl and displayName are props
 // Add styles as classes prop
 withStyles(styles)
);
