import React, { useMemo, useState } from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
import IconButton from '@material-ui/core/IconButton';
import MenuIcon from '@material-ui/icons/Menu';
import classNames from 'classnames';
import { LIST_PATH } from 'constants/paths';
import AccountMenu from './AccountMenu';
import LoginMenu from './LoginMenu';
import { useAuth } from "containers/App/use-auth.js";
import Can from "../Can";

// reactstrap components
import {
  Button,
  Collapse,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
  UncontrolledDropdown,
  Input,
  InputGroup,
  NavbarBrand,
  Navbar,
  NavLink,
  Nav,
  Container,
  Modal,
  UncontrolledTooltip
} from "reactstrap";

const AdminNavbar = ({
handleLogout,
goToProfile,
goToAccount,
goToHowTo,
goToDashboard,
brandText,
profile,
handleMiniClick,
sidebarOpened,
toggleSidebar,
location
}) => {

  const [collapseOpen, setCollapseOpen] = React.useState(false);
  const [modalSearch, setModalSearch] = React.useState(false);
  const [color, setColor] = React.useState("navbar-transparent");

  React.useEffect(() => {
    window.addEventListener("resize", updateColor);
    return function cleanup() {
      window.removeEventListener("resize", updateColor);
    };
  });
  // function that adds color white/transparent to the navbar on resize (this is for the collapse)
  const updateColor = () => {
    if (window.innerWidth < 993 && collapseOpen) {
      setColor("bg-white");
    } else {
      setColor("navbar-transparent");
    }
  };
  // this function opens and closes the collapse on small devices
  const toggleCollapse = () => {
    if (collapseOpen) {
      setColor("navbar-transparent");
    } else {
      setColor("bg-white");
    }
    setCollapseOpen(!collapseOpen);
  };
  // this function is to open the Search modal
  const toggleModalSearch = () => {
    setModalSearch(!modalSearch);
  };


  const handleLogoutClick = handleLogout;
  const handleProfile =  goToProfile;
  const handleAccount =  goToAccount;
  const handleHowTo =  goToHowTo;
  const handleDashboard =  goToDashboard;


  return (
  <>
  <Navbar
    className={classNames("navbar-absolute", {
      [color]:location.pathname.indexOf("full-screen-map") === -1,
    })}
    expand="lg"
  >
      <Container fluid>
        <div className="navbar-wrapper">
          <div className="navbar-minimize d-inline">
            <Button
              className="minimize-sidebar btn-just-icon"
              color="link"
              id="tooltip209599"
              onClick={handleMiniClick}
            >
              <i className="tim-icons icon-align-center visible-on-sidebar-regular" />
              <i className="tim-icons icon-bullet-list-67 visible-on-sidebar-mini" />
            </Button>

            <UncontrolledTooltip
              delay={0}
              target="tooltip209599"
              placement="right"
            >
              Minimise Side Menu
            </UncontrolledTooltip>
          </div>
          <div
            className={classNames("navbar-toggle d-inline", {
              toggled: false
            })}
          >
          <NavbarBrand href="#pablo" onClick={(e) => e.preventDefault()}>
            {brandText}
          </NavbarBrand>
            <button
              className="navbar-toggler"
              type="button"
              onClick={toggleSidebar}
            >
            </button>
          </div>
        </div>
        <button
          className="navbar-toggler"
          type="button"
          data-toggle="collapse"
          data-target="#navigation"
          aria-expanded="false"
          aria-label="Toggle navigation"
          onClick={toggleCollapse}
        >
          <span className="navbar-toggler-bar navbar-kebab" />
          <span className="navbar-toggler-bar navbar-kebab" />
          <span className="navbar-toggler-bar navbar-kebab" />
        </button>
        <Collapse navbar isOpen={collapseOpen}>
          <Nav className="ml-auto" navbar>
            <UncontrolledDropdown nav>
              <DropdownToggle
                caret
                color="default"
                data-toggle="dropdown"
                nav
              >
                <div className="notification d-none d-lg-block d-xl-block" />
                <i className="tim-icons icon-sound-wave" />
                <p className="d-lg-none">Notifications</p>
              </DropdownToggle>
              <DropdownMenu className="dropdown-navbar" right tag="ul">
                <NavLink tag="li">
                  <DropdownItem className="nav-item">
                    Screens have been updated
                  </DropdownItem>
                </NavLink>
              </DropdownMenu>
            </UncontrolledDropdown>
          <UncontrolledDropdown nav>
              <DropdownToggle
                caret
                color="default"
                data-toggle="dropdown"
                nav
                onClick={e => e.preventDefault()}
              >
                <i className="tim-icons icon-single-02" />
                <b className="caret d-none d-lg-block d-xl-block" />
                <p className="d-lg-none">Log Out</p>
              </DropdownToggle>
              <DropdownMenu className="dropdown-navbar" right tag="ul">
                <NavLink onClick={handleProfile} tag="li">
                  <DropdownItem className="nav-item">Profile</DropdownItem>
                </NavLink>
                <NavLink onClick={handleHowTo} tag="li">
                  <DropdownItem className="nav-item">How To</DropdownItem>
                </NavLink>
                <Can
                  role={profile.role}
                  perform={"Account"+":visit"}
                  yes={() => (
                    <NavLink onClick={handleAccount} tag="li">
                      <DropdownItem className="nav-item">Account</DropdownItem>
                    </NavLink>
                  )}
                  no={() =>  null }
                />
                <DropdownItem divider tag="li" />
                <NavLink onClick={handleLogoutClick} tag="li">
                  <DropdownItem className="nav-item">Log out</DropdownItem>
                </NavLink>
              </DropdownMenu>
            </UncontrolledDropdown>
            <li className="separator d-lg-none" />
          </Nav>
        </Collapse>
      </Container>
    </Navbar>
    <Modal
      modalClassName="modal-search"
      isOpen={modalSearch}
      toggle={toggleModalSearch}
    >
      <div className="modal-header">
        <Input id="inlineFormInputGroup" placeholder="SEARCH ...coming soon" type="text" />
        <button
          aria-label="Close"
          className="close"
          data-dismiss="modal"
          type="button"
          onClick={toggleModalSearch}
        >
          <i className="tim-icons icon-simple-remove" />
        </button>
      </div>
    </Modal>
  </>
);

}


AdminNavbar.propTypes = {
  classes: PropTypes.object.isRequired, // from enhancer (withStyles)
  displayName: PropTypes.string, // from enhancer (flattenProps - profile)
  handleLogout: PropTypes.func.isRequired, // from enhancer (withHandlers - firebase)
  goToProfile: PropTypes.func.isRequired, // from enhancer (withHandlers - firebase)
  goToAccount: PropTypes.func.isRequired, // from enhancer (withHandlers - firebase)
  goToHowTo: PropTypes.func.isRequired, // from enhancer (withHandlers - firebase)
  avatarUrl: PropTypes.string, // from enhancer (flattenProps - profile)
  authExists: PropTypes.bool, // from enhancer (withProps - auth)
  anchorEl: PropTypes.object,
  profile: PropTypes.object, // from enhancer (withStateHandlers - handleMenu)
  open: PropTypes.bool // from enhancer (setPropTypes)
};

export default AdminNavbar;
