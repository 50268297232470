import { applyMiddleware, compose, createStore } from 'redux';
import thunk from 'redux-thunk';
import firebase from 'firebase/app';
import 'firebase/database';
import 'firebase/performance';
import 'firebase/auth';
import 'firebase/storage';
import 'firebase/firestore';
import makeRootReducer from './reducers';
import { createFirestoreInstance } from 'redux-firestore';
import storage from 'redux-persist/lib/storage'; // defaults to localStorage for web;
import { firebaseConfig as fbConfig, reduxFirebase as rrfConfig, env } from '../config';

export default (initialState = {}) => {
  // ======================================================
  // Redux + Firebase Config (react-redux-firebase & redux-firestore)
  // ======================================================
  const defaultRRFConfig = {
    userProfile: 'users', // root that user profiles are written to
    updateProfileOnLogin: false, // enable/disable updating of profile on login
    presence: 'presence', // list currently online users under "presence" path in RTDB
    sessions: null, // Skip storing of sessions
    enableLogging: false, // enable/disable Firebase Database Logging
    profileParamsToPopulate: [
      { child: 'role', root: 'roles' } // populates user's role with matching role object from roles
    ],
    enableClaims: true,
    profileFactory: ({ user }, profileData) => ({
      ...profileData,
      email:
        user.email ||
        (user.providerData && user.providerData[0] && user.providerData[0].email ? user.providerData[0].email : ''),
      role: 'admin',
      providerData: user.providerData
    })
  };

  // Combine default config with overrides if they exist (set within .firebaserc)
  const combinedConfig = rrfConfig ? { ...defaultRRFConfig, ...rrfConfig } : defaultRRFConfig;

  // ======================================================
  // Store Enhancers
  // ======================================================
  const enhancers = [];

  if (env === 'local') {
    const devToolsExtension = window.devToolsExtension;
    if (typeof devToolsExtension === 'function') {
      enhancers.push(devToolsExtension());
    }
  }

  // ======================================================
  // Middleware Configuration
  // ======================================================
  const middleware = [
    thunk.withExtraArgument(firebase)
    // This is where you add other middleware like redux-observable
  ];

  // ======================================================
  // Firebase Initialization
  // ======================================================
  firebase.initializeApp(fbConfig);
  const perf = firebase.performance();


  const rootReducer = makeRootReducer();
  // ======================================================
  // Store Instantiation and HMR Setup
  // ======================================================
  const store = createStore(
    rootReducer,
    initialState,
    compose(
      applyMiddleware(...middleware),
      ...enhancers
    )
  );

  const rrfProps = {
    firebase,
    config: combinedConfig,
    dispatch: store.dispatch,
    createFirestoreInstance // <- needed if using firestore
  };
  store.rrfProps = rrfProps;

  store.asyncReducers = {};

  if (module.hot) {
    module.hot.accept('./reducers', () => {
      const reducers = require('./reducers').default;
      store.replaceReducer(reducers(store.asyncReducers));
    });
  }

  return { store };
};
